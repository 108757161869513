<template>
  <main>
    <HomeBanner/>
    <HomeSobre/>
    <HomeServicos/>
    <HomeNoticias/>
    <HomeEventos/>
    <HomeNotificacoes/>
    <HomeGerenciamento/>
  </main>
</template>

<script>

import HomeBanner from "@/components/home/home_banner";
import HomeSobre from "@/components/home/home_sobre";
import HomeServicos from "@/components/home/home_servicos";
import HomeNoticias from "@/components/home/home_noticias";
import HomeEventos from "@/components/home/home_eventos";
import HomeNotificacoes from "@/components/home/home_notificacoes";
import HomeGerenciamento from "@/components/home/home_gerenciamento";

export default {
name: "Home",
  components: {HomeGerenciamento, HomeNotificacoes, HomeEventos, HomeNoticias, HomeServicos, HomeSobre, HomeBanner}
}
</script>

<style scoped>
  #home {
    background-image: url(../assets/home-9-b-bg.png);
  }
</style>