<template>
  <section id="idautentiko" class="section" >
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6 dark-color">
          <h2 class="dark-color font-w-600 m-20px-b p-20px-b theme-after after-50px font-40px md-font-38px sm-font-28px">IDAutentiko</h2>
          <h5>Os profissinionais inscritos em sua organização/empresa podem usar nosso Aplicativo como cartão de identificação digital de forma simples e segura.</h5>
          <h5>O app possui várias camadas de segurança para proteger a identidade do usuário evitando fraudes.</h5>
          <div class="p-30px-t">
            <!-- <a class="m-btn m-btn-radius m-btn-t-theme" href="#dashboard">Saiba mais</a> -->
          </div>
          <div class="col-lg-4">
            <div class="row align-items-center">
              <div class="col-sm-12">
                <div class="p-30px-t">
                  <a class="m-btn m-btn-radius m-btn-t-theme" href="#app_more">Saiba mais</a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-6">
          <div class="row align-items-center">
            <div class="col-sm-12">
              <!-- feature box -->
              <div class="text-center transition m-30px-tb">
                <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
                  <ol class="carousel-indicators">
                    <li data-target="#carouselExampleIndicators" data-slide-to="0" class=""></li>
                    <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                  </ol>
                  <div class="carousel-inner">
                    <div class="carousel-item active">
                      <img src="../../assets/app1.png" width="310" height="590">
                    </div>
                    <div class="carousel-item">
                      <img src="../../assets/app2.png" width="310" height="590">
                    </div>
                    <div class="carousel-item">
                      <img src="../../assets/app3.png"  width="310" height="590">
                    </div>
                  </div>
                  <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="sr-only">Previous</span>
                  </a>
                  <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="sr-only">Next</span>
                  </a>
                </div>
              </div><!-- end feature box -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
name: "AutentikoSobre"
}
</script>

<style scoped>
.carousel-control-prev-icon,
.carousel-control-next-icon {
  height: 100px;
  width: 100px;
  outline: black;
  background-size: 100%, 100%;
  border-radius: 50%;
  background-image: none;
}

.carousel-control-next-icon:after
{
  content: '>';
  font-size: 55px;
  color: #0D21A1;
}

.carousel-control-prev-icon:after {
  content: '<';
  font-size: 55px;
  color: #0D21A1;
}
</style>