<template>
  <section id="about1" class="section gray-bg" style="background-color: #EF476F;">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6 white-color">
          <h2 class="white-color font-w-600 m-20px-b p-20px-b after-50px font-40px md-font-38px sm-font-28px">Gerenciamento</h2>
          <h4>O aplicativo é totalmente gerenciável através de uma plataforma web.</h4>
          <ul class="list-items">
            <li>Monitore, controle e administre prazos separados por Secretaria e Setor.</li>
            <li>Tenha em mãos relatórios gerenciais e analíticos para análises mais completas.</li>
            <li>Obtenha mais uma ferramenta que auxilie na aplicação dos recursos públicos, de forma eficiente e participativa.</li>
            <li>Facilite a obtenção de serviços e esteja no dia-a-dia de todos os cidadãos.</li>
          </ul>
        </div>

        <div class="col-lg-6">
          <div class="row align-items-center">
            <div class="col-sm-6">
              <!-- feature box -->
              <div class="text-center transition hover-top box-shadow white-bg border-radius-5 p-20px border-bottom-5 border-color-theme m-30px-tb">
                <div class="f-icon-100px font-30px white-color border-radius-effect">
                  <i class="icon-desktop"><img src="../../assets/crescimento_pessoa.png"></i>
                </div>
                <div class="p-25px-t">
                  <h5 class="font-w-500 font-18px dark-color m-0px">Relatórios</h5>
                </div>
              </div>
              <!-- end feature box -->

            </div>
            <div class="col-sm-6">
              <!-- feature box -->
              <div class="text-center transition hover-top box-shadow white-bg border-radius-5 p-20px border-bottom-5 border-color-theme m-30px-tb">
                <div class="f-icon-100px font-30px white-color border-radius-effect" >
                  <i class="icon-tools"><img src="../../assets/calendar.png"></i>
                </div>
                <div class="p-25px-t">
                  <h5 class="font-w-500 font-18px dark-color m-0px">Controle de Atendimento</h5>
                </div>
              </div>
              <!-- end feature box -->
              <!-- feature box -->
              <div class="text-center transition hover-top box-shadow white-bg border-radius-5 p-20px border-bottom-5 border-color-theme m-30px-tb">
                <div class=" font-30px white-color border-radius-effect">
                  <i class="icon-tools"><img src="../../assets/board.png"></i>
                </div>
                <div class="p-25px-t">
                  <h5 class="font-w-500 font-18px dark-color m-0px">Gestão Clara</h5>
                </div>
              </div>
              <!-- end feature box -->

            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

</template>

<script>
export default {
name: "HomeGerenciamento"
}
</script>

<style scoped>

</style>