<template>
  <section id="app_more" class="section gray-bg" style="background-color: #6f42c1;">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-12 white-color">
          <h2 class="m-20px-b p-20px-b after-50px font-40px md-font-38px sm-font-28px">Segurança na palma da mão</h2>
          <h4>Para manter a confiabilidade e segurança dos dados dos usuários o IDAutentiko conta com:</h4>
          <ul class="list-items">
            <li><h6>Validação de credenciais dos profissionais em reuniões presenciais ou online.</h6></li>
            <li><h6>Praticidade do QRCode que é gerado individualmente para verficar a autenticidade dos dados.</h6></li>
            <li><h6>Atualização da validade das credenciais a qualquer momento através do nosso painel administrativo.</h6></li>
            <li><h6>Receba notícias ou alertas de eventos em forma de Notificações ou SMS.</h6></li>
          </ul>
          <div class="row">
            <div class="col-sm-3">
              <!-- feature box -->
              <div class="text-center transition hover-top box-shadow white-bg border-radius-5 p-40px border-bottom-5 border-color-theme m-20px-tb  border-bottom-5 border-color-theme m-30px-tb">
                <div class="f-icon-100px font-30px theme-bg white-color border-radius-effect" style="background-color: #f3efff">
                  <img src="../../assets/qrcode.png" >
                </div>
                <div class="p-25px-t">
                  <h5 class="font-w-500 font-18px dark-color m-0px">Qrcode</h5>
                </div>
              </div>
              <!-- end feature box -->
            </div>
            <div class="col-sm-3">
              <!-- feature box -->
              <div class="text-center transition hover-top box-shadow white-bg border-radius-5 p-40px border-bottom-5 border-color-theme m-30px-tb">
                <div class="f-icon-100px font-30px theme-bg white-color border-radius-effect" style="background-color: #ffdfb6">
                  <img src="../../assets/autenticidade.png">
                </div>
                <div class="p-25px-t">
                  <h5 class="font-w-500 font-18px dark-color m-0px">Autenticidade</h5>
                </div>
              </div>
              <!-- end feature box -->
            </div>
            <div class="col-sm-3">
              <!-- feature box -->
              <div class="text-center transition hover-top box-shadow white-bg border-radius-5 p-40px border-bottom-5 border-color-theme m-20px-tb  border-bottom-5 border-color-theme m-30px-tb">
                <div class="f-icon-100px font-30px theme-bg white-color border-radius-effect" style="background-color: #92e781">
                  <img src="../../assets/security.png">
                </div>
                <div class="p-25px-t">
                  <h5 class="font-w-500 font-18px dark-color m-0px"> Segurança</h5>
                </div>
              </div>
              <!-- end feature box -->
            </div>
            <div class="col-sm-3">
              <!-- feature box -->
              <div class="text-center transition hover-top box-shadow white-bg border-radius-5 p-40px border-bottom-5 border-color-theme m-30px-tb">
                <div class="f-icon-100px font-30px theme-bg white-color border-radius-effect" style="background-color: #fff799">
                  <img src="../../assets/notifications.png">
                </div>
                <div class="p-25px-t">
                  <h5 class="font-w-500 font-18px dark-color m-0px">Notificações</h5>
                </div>
              </div>
              <!-- end feature box -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
name: "AutentikoServicos"
}
</script>

<style scoped>

</style>