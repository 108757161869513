<template>
  <section id="services" class="section gray-bg" style="background-color: #6f42c1;">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-8 white-color">
          <h2 class="font-w-600 m-20px-b p-20px-b after-50px font-40px md-font-38px sm-font-28px">Serviços</h2>
          <h4>Todos os serviços disponíveis na palma da mão. Fácil acesso, organizados de maneira simples e inteligente.</h4>
          <p>Trabalhamos junto as entidades para promover uma baixa curva de aprendizagem. Facilitar para o cidadão que sinta-se parte da administração pública é nossa meta.</p>
          <p>Com módulos configuráveis fornecemos um ambiente que se adequa as melhores práticas de usabilidade sem perder a identidade com a Gestão.</p>
          <div class="row">
            <div class="col-sm-3">
              <!-- protocolo img -->
              <div class="text-center transition hover-top box-shadow white-bg border-radius-5 p-10px border-bottom-5 border-color-theme m-30px-tb">
                <div class="f-icon-100px font-30px white-color border-radius-effect">
                  <i class="icon-desktop"><img src="../../assets/protocolo.png"></i>
                </div>
                <div class="p-25px-t">
                  <h5 class="font-w-500 font-14px dark-color m-0px">Protocolo</h5>
                </div>
              </div>
              <!-- end protocolo img box -->
            </div>

            <div class="col-sm-3">
              <!-- protocolo img -->
              <div class="text-center transition hover-top box-shadow white-bg border-radius-5 p-10px border-bottom-5 border-color-theme m-30px-tb">
                <div class="f-icon-100px font-30px white-color border-radius-effect" style="background-color: #F0EBD8">
                  <i class="icon-desktop"><img src="../../assets/nurse.png"></i>
                </div>
                <div class="p-25px-t">
                  <h5 class="font-w-500 font-14px dark-color m-0px">Saúde</h5>
                </div>
              </div>
              <!-- end protocolo img box -->
            </div>

            <div class="col-sm-3">
              <!-- protocolo img -->
              <div class="text-center transition hover-top box-shadow white-bg border-radius-5 p-10px border-bottom-5 border-color-theme m-30px-tb">
                <div class="f-icon-100px font-30px white-color border-radius-effect" style="background-color: #F8D112;">
                  <i class="icon-desktop"><img src="../../assets/arrecadacao.png"></i>
                </div>
                <div class="p-25px-t">
                  <h5 class="font-w-500 font-14px dark-color m-0px">Fazenda</h5>
                </div>
              </div>
              <!-- end protocolo img box -->
            </div>
            <div class="col-sm-3">
              <!-- protocolo img -->
              <div class="text-center transition hover-top box-shadow white-bg border-radius-5 p-10px border-bottom-5 border-color-theme m-30px-tb">
                <div class="f-icon-100px font-30px white-color border-radius-effect" style="background-color: #3FCA8C;">
                  <i class="icon-desktop"><img src="../../assets/obras.png"></i>
                </div>
                <div class="p-25px-t">
                  <h5 class="font-w-500 font-14px dark-color m-0px">Obras</h5>
                </div>
              </div>
              <!-- end protocolo img box -->
            </div>
          </div>
          <div class="row">

            <div class="col-sm-3">
              <!-- protocolo img -->
              <div class="text-center transition hover-top box-shadow white-bg border-radius-5 p-10px border-bottom-5 border-color-theme m-30px-tb">
                <div class="f-icon-100px font-30px white-color border-radius-effect" style="background-color: #FCF300;">
                  <i class="icon-desktop"><img src="../../assets/transporte.png"></i>
                </div>
                <div class="p-25px-t">
                  <h5 class="font-w-500 font-14px dark-color m-0px">Trânsito</h5>
                </div>
              </div>
              <!-- end protocolo img box -->
            </div>
            <div class="col-sm-3">
              <!-- protocolo img -->
              <div class="text-center transition hover-top box-shadow white-bg border-radius-5 p-10px border-bottom-5 border-color-theme m-30px-tb">
                <div class="f-icon-100px font-30px white-color border-radius-effect" style="background-color: #DBFCFF;">
                  <i class="icon-desktop"><img src="../../assets/meio-ambiente.png"></i>
                </div>
                <div class="p-25px-t">
                  <h5 class="font-w-500 font-14px dark-color m-0px">Meio Ambiente</h5>
                </div>
              </div>
              <!-- end protocolo img box -->
            </div>

            <div class="col-sm-3">
              <!-- protocolo img -->
              <div class="text-center transition hover-top box-shadow white-bg border-radius-5 p-10px border-bottom-5 border-color-theme m-30px-tb">
                <div class="f-icon-100px font-30px white-color border-radius-effect">
                  <i class="icon-desktop"><img src="../../assets/quadro_escolar.png"></i>
                </div>
                <div class="p-25px-t">
                  <h5 class="font-w-500 font-14px dark-color m-0px">Educação</h5>
                </div>
              </div>
              <!-- end protocolo img box -->
            </div>

            <div class="col-sm-3">
              <!-- protocolo img -->
              <div class="text-center transition hover-top box-shadow white-bg border-radius-5 p-10px border-bottom-5 border-color-theme m-30px-tb">
                <div class="f-icon-100px font-30px white-color border-radius-effect">
                  <i class="icon-desktop"><img src="../../assets/chatbot.png"></i>
                </div>
                <div class="p-25px-t">
                  <h5 class="font-w-500 font-14px dark-color m-0px">Integrações</h5>
                </div>
              </div>
              <!-- end protocolo img box -->
            </div>
          </div>

        </div>

        <div class="col-lg-4">
          <div class="row align-items-center">
            <div class="col-sm-12">
              <!-- feature box -->
              <div class="text-center transition hover-top m-30px-tb">
                <img src="../../assets/service_app.png" width="290">
                <div class="p-25px-t">
                  <h5 class="font-w-500 font-18px dark-color m-0px"></h5>
                </div>
              </div>
              <!-- end feature box -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

</template>

<script>
export default {
name: "HomeServicos"
}
</script>

<style scoped>

</style>