<template>
  <AmbienteBanner/>
</template>

<script>
import AmbienteBanner from "@/components/meioambiente/ambiente_banner";
export default {
name: "AmbienteHome",
  components: {AmbienteBanner}
}
</script>

<style scoped>

</style>