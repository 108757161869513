<template>
  <section id="noticias" class="section gray-bg">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-8 dark-color">
          <h2 class="dark-color font-w-600 m-20px-b p-20px-b after-50px font-40px md-font-38px sm-font-28px">Notícias</h2>
          <h5>Deixe o cidadão sempre informado com a nossa lista de notícias.</h5>
          <h5>Adicione informações e assuntos relevantes sobre tudo que acontece sobre a Gestão.</h5>
          <ul class="list-items">
            <li>Cadstre e Gerencie notícias, disponibilizando de forma fácil no aplicativo.</li>
            <li>Crie uma forma fácil de compartilhamento de notícias.</li>
            <li>Envie notificações sempre que uma notícia de interesse é postada na plataforma.</li>
            <li>Avalie o alcance do material disponibilizado.</li>
            <li>Compartilhe material institucional, fotos e vídeos de forma fácil através das notícias.</li>
          </ul>
        </div>

        <div class="col-lg-4">
          <div class="row align-items-center">
            <div class="col-sm-12">
              <!-- feature box -->
              <div class="text-center transition hover-top m-30px-tb">
                <img src="../../assets/noticias_app.png" width="290">
                <div class="p-25px-t">
                  <h5 class="font-w-500 font-18px dark-color m-0px"></h5>
                </div>
              </div>
              <!-- end feature box -->
            </div>

          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
name: "HomeNoticias"
}
</script>

<style scoped>

</style>