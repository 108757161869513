<template>
  <section id="home" class="effect-section bg-cover">
    <div class="atk-banner container container-large">
      <div class="row full-screen align-items-center justify-content-center">
        <div class="col-12 col-md-8 col-lg-6 col-xl-4">
          <div class="p-60px-t md-m-60px-t">
            <h3 class="white-color font-w-600 font-42px md-font-40px sm-font-30px m-25px-b">Autentiko ProID</h3>
            <p class="white-color font-18px sm-font-15px m-0px">
              A Autentiko uma solução SmartGovs, trás para o mercado o que há de mais atual em tecnologia e compliance de dados na forma de um sistema de Indentidade Digital para o setor público e também para o setor privado.
            </p>
          </div>
        </div>
        <div class="col-12 col-lg-6 col-xl-8 text-center">
          <img src="../../assets/home-3-b1.svg">
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "AutentikoBanner"
}
</script>

<style scoped>
.atk-banner {
  background-image: url(../../assets/home-3-1-bg.png);
}
#home {
  background-color: #03c;
}
</style>