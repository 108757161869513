<template>
  <section id="dashboard" class="section gray-bg" style="background-color: #6f42c1;">
    <div class="container">
      <div class="row align-items-center">

        <div class="col-lg-6 white-color font-w-350">
          <h2 class=" m-20px-b p-20px-b after-50px font-40px md-font-38px sm-font-28px">Relatórios e Segurança</h2>
          <h4>O painel administrativo do IDAutentiko simplifica todo o gerencimento com:</h4>
          <ul class="list-items">
            <li><h6>Cadastro de todos os dados dos usuários através importação de arquivos .CSV.</h6></li>
            <li><h6>Dados estatísticos de uso relacionados aos usuários.</h6></li>
            <li><h6>Identifique e corrija irregularidades facilmente com o painel de <i>Usuários com Problemas</i>.</h6></li>
            <li><h6>Envie para todos os profissionais enquetes ou quizes, informativos e alertas.</h6></li>
            <li><h6>Ative ou desative com apenas um clique a validade das credenciais a qualquer momento.</h6></li>
          </ul>
        </div>

        <div class="col-lg-6">
          <div class="row align-items-center">
            <div class="col-sm-6">
              <!-- feature box -->
              <div class="text-center transition hover-top box-shadow white-bg border-radius-5 p-40px border-bottom-5 border-color-theme m-20px-tb  border-bottom-5 border-color-theme m-30px-tb">
                <div class="f-icon-100px font-30px theme-bg white-color border-radius-effect" style="background-color: #f3efff">
                  <img src="../../assets/csv.png" >
                </div>
                <div class="p-25px-t">
                  <h5 class="font-w-500 font-18px dark-color m-0px">.csv</h5>
                </div>
              </div>
              <!-- end feature box -->
            </div>
            <div class="col-sm-6">
              <!-- feature box -->
              <div class="text-center transition hover-top box-shadow white-bg border-radius-5 p-40px border-bottom-5 border-color-theme m-30px-tb">
                <div class="f-icon-100px font-30px theme-bg white-color border-radius-effect" style="background-color: #ffdfb6">
                  <img src="../../assets/stats.png">
                </div>
                <div class="p-25px-t">
                  <h5 class="font-w-500 font-18px dark-color m-0px">Estatísticas</h5>
                </div>
              </div>
              <!-- end feature box -->

              <!-- feature box -->
              <div class="text-center transition hover-top box-shadow white-bg border-radius-5 p-40px border-bottom-5 border-color-theme m-20px-tb  border-bottom-5 border-color-theme m-30px-tb">
                <div class="f-icon-100px font-30px theme-bg white-color border-radius-effect" style="background-color: #92e781">
                  <img src="../../assets/userok.png">
                </div>
                <div class="p-25px-t">
                  <h5 class="font-w-500 font-18px dark-color m-0px">Regularizado</h5>
                </div>
              </div>
              <!-- end feature box -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
name: "AutentikoPanel"
}
</script>

<style scoped>

</style>