<template>
  <section id="abous2t" class="section gray-bg">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-4">
          <div class="row align-items-center">
            <div class="col-sm-12">
              <!-- feature box -->
              <div class="text-center transition hover-top m-30px-tb">
                <img src="../../assets/notification_app.png" width="290">
                <div class="p-25px-t">
                  <h5 class="font-w-500 font-18px dark-color m-0px"></h5>
                </div>
              </div>
              <!-- end feature box -->

            </div>
          </div>
        </div>
        <div class="col-lg-8 dark-color">
          <h2 class="dark-color font-w-600 m-20px-b p-20px-b after-50px font-40px md-font-38px sm-font-28px">Notificações</h2>
          <h4>Envie alertas e informativos sobre sua cidade em tempo real para todos os cidadãos que estiverem usando o nosso app!</h4>
          <ul class="list-items">
            <li>Envie notificações da Defesa Civil direto pelo aplicativo.</li>
            <li>Notifique sempre que um serviço ou protocolo receber uma interação.</li>
            <li>Envie imagens junto na notificação.</li>
          </ul>
        </div>

      </div>
    </div>
  </section>

</template>

<script>
export default {
name: "HomeNotificacoes"
}
</script>

<style scoped>

</style>