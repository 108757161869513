<template>
  <section class="section gray-bg">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6 dark-color font-w-350">
          <h2 class=" m-20px-b p-20px-b after-50px font-40px md-font-38px sm-font-28px">Gerenciador Completo</h2>
          <h4>Além do app, o IDAutentiko Gerenciador irá proporcionar total controle sobre todos os profissionais e funcionários que possuirão o seu IDAutentiko.</h4>
          <div class="col-lg-4">
            <div class="row align-items-center">
              <div class="col-sm-12">
                <div class="p-30px-t">
                  <a class="m-btn m-btn-radius m-btn-t-theme" href="#dashboard">Saiba mais</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="row align-items-center">
            <div class="col-sm-12">
              <!-- feature box -->
              <div class="text-center transition hover-top m-30px-tb">
                <div class="">
                  <img class="imagedashboard" src="../../assets/dashboard.png" alt=""
                       style=" position: relative; top: 10px; left: 2px; z-index: 1;">
                </div>
              </div>
              <!-- end feature box -->
            </div>
          </div>
        </div>
      </div>

    </div>
  </section>
</template>

<script>
export default {
name: "AutentikoGerenciador"
}
</script>

<style scoped>

</style>