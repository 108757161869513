<template>
  <AutentikoBanner/>
  <AutentikoSobre/>
  <AutentikoServicos/>
  <AutentikoGerenciador/>
  <AutentikoPanel/>
</template>

<script>

import AutentikoBanner from "@/components/autentiko/autentiko_banner";
import AutentikoSobre from "@/components/autentiko/autentiko_sobre";
import AutentikoServicos from "@/components/autentiko/autentiko_servicos";
import AutentikoGerenciador from "@/components/autentiko/autentiko_gerenciador";
import AutentikoPanel from "@/components/autentiko/autentiko_panel";


export default {
name: "AutentikoHome",
  components:{AutentikoPanel, AutentikoGerenciador, AutentikoServicos, AutentikoSobre, AutentikoBanner}
}
</script>

<style scoped>

</style>