import { createRouter, createWebHistory } from "vue-router"
import Home from "@/components/home.vue"
import Autentiko from "@/components/autentiko/autentiko_home"
import Ambiente from "@/components/meioambiente/home"
import LGPD from "@/components/lgpd/home"
import PageNotFound from "@/components/PageNotFound.vue"


const routes = [
    {
        path: "/",
        name: "",
        component: Home,
    },
    {
        path: '/autentiko',
        name: "autentiko",
        component: Autentiko,
    },
    {
        path: '/ambiente',
        name: "ambiente",
        component: Ambiente,
    },
    {
        path: '/lgpd',
        name: "lgpd",
        component: LGPD,
    },
    {
        path: '/links',
        name: "links",
        component: LGPD,
    },
    {
        path: '/:catchAll(.*)*',
        name: "PageNotFound",
        component: PageNotFound,
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router