<template>
  <header class="header-nav header-white">
    <div class="fixed-header-bar">
      <!-- Header Middle -->
      <div class="navbar navbar-default navbar-expand-lg main-navbar">
        <div class="container container-large">
          <div class="navbar-brand">
            <router-link :to="'/'">
              <img class="logo-size logo-light" src="../assets/startup_color.svg" title="" alt="">
              <span class="logo-light white-color"> SmartGovs</span>
            </router-link>
          </div>

          <div class="navbar-collapse justify-content-end collapse" id="navbar-collapse-toggle">
            <ul class="navbar-nav m-auto align-items-lg-center">
              <li><router-link :to="'/'">SmartGovs - Gestão Mobile </router-link></li>
              <li><router-link :to="'autentiko'"> | Autentiko ProID </router-link></li>
              <li><router-link :to="'ambiente'"> | Meio Ambiente </router-link></li>
              <li><router-link :to="'lgpd'"> | LGPD </router-link></li>
              <li><router-link :to="'links'"> | Links Úteis </router-link></li>
            </ul>
          </div>
          <div class="extra-menu d-flex align-items-center">
            <a class="m-btn m-btn-radius m-btn-theme2nd d-none d-md-block" href="#">Fale com um Especialista</a>
            <button type="button" class="navbar-toggler collapsed" data-toggle="collapse" data-target="#navbar-collapse-toggle" aria-expanded="false">
              <span class="icon-bar"></span>
            </button>
          </div>
        </div>
      </div>
      <!-- End Header Middle -->
    </div>
  </header>
</template>

<script>
export default {
name: "menu"
}
</script>

<style scoped>
.logo-size{
  height: 2em;
}
.logo-light{
  color: #0b0b0b !important;
}
.header-white {
  box-shadow: 0px 3px 10px 0px rgb(38 59 94);
}
</style>