<template>
  <section id="abosut" class="section gray-bg white-color" style="background-color: #745CFF;">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-4">
          <div class="row align-items-center">
            <div class="col-sm-12">
              <!-- feature box -->
              <div class="text-center transition hover-top m-30px-tb">
                <img src="../../assets/events_app.png" width="290">
                <div class="p-25px-t">
                  <h5 class="font-w-500 font-18px dark-color m-0px"></h5>
                </div>
              </div>
              <!-- end feature box -->
            </div>
          </div>
        </div>
        <div class="col-lg-8 white-color">
          <h2 class="white-color font-w-600 m-20px-b p-20px-b after-50px font-40px md-font-38px sm-font-28px">Eventos</h2>
          <h4>Promova eventos de forma eficiente, levando a todos as principais informações da vida cultural, cursos, palestras e workshops promovidos.</h4>
          <ul class="list-items">
            <li>Gerencie seus eventos, presenciais ou online.</li>
            <li>Controle os participantes de eventos e emita lista de presença.</li>
            <li>Interaja durante os eventos, enviando notificações e perguntas.</li>
          </ul>
        </div>

      </div>
    </div>
  </section>

</template>

<script>
export default {
name: "HomeEventos"
}
</script>

<style scoped>

</style>