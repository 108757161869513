<template>
  <section id="home" class="effect-section bg-cover">
    <div class="container container-large">
      <div class="row full-screen align-items-center justify-content-center">
        <div class="col-12 col-md-8 col-lg-6 col-xl-4">
          <div class="p-60px-t md-m-60px-t">
            <h3 class="white-color font-w-600 font-42px md-font-40px sm-font-30px m-25px-b">Entregue mais
              valor com Tecnologia</h3>
            <p class="white-color font-18px sm-font-15px m-0px">
              Acreditamos que criar soluções que geram
              boas relações entre Governo e Sociedade é sempre a melhor opção. Trazemos soluções que aproxima, escuta e
              incentiva a particpação popular em todas as áreas da Gestão Pública.
            </p>
          </div>
        </div>
        <div class="col-12 col-lg-6 col-xl-8 text-center">
          <img src="../../assets/home-9-b1.svg">
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "HomeBanner"
}
</script>

<style scoped>
#home {
  background-image: url(../../assets/home-9-b-bg.png);
}
</style>