<template>
  <LGPDBanner/>
</template>

<script>
import LGPDBanner from "./lgpd_banner";
export default {
name: "LGPDHome",
  components: {LGPDBanner}
}
</script>

<style scoped>

</style>