<template>
  <section id="about" class="section gray-bg">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6 dark-color">
          <h2 class="dark-color font-w-600 m-20px-b p-20px-b theme-after after-50px font-40px md-font-38px sm-font-28px">SmartGovs: Prefeitura Digital e Conselho Digital</h2>
          <h4>A transformação digital chegou à gestão pública.</h4>

          <p> Novos meios são necessários para estabelecer uma boa relação entre o sociedade e seus representantes. </p>
          <p>Através de soluções móveis trazemos um conjunto de ferramentas que aproxima o cidadão, oferencedo serviços e participação direta nas diversas áreas e ações da gestão pública.</p>
        </div>

        <div class="col-lg-6">
          <div class="row align-items-center">
            <div class="col-sm-6">
              <!-- feature box -->
              <div class="text-center transition hover-top box-shadow white-bg border-radius-5 p-40px border-bottom-5 border-color-theme m-30px-tb">
                <div class="f-icon-100px font-30px white-color border-radius-effect" style="background-color: #8C2155;">
                  <i class="icon-desktop"><img src="../../assets/people.png"></i>
                </div>
                <div class="p-25px-t">
                  <h5 class="font-w-500 font-18px dark-color m-0px">Cidadão</h5>
                </div>
              </div>
              <!-- end feature box -->

            </div>
            <div class="col-sm-6">
              <!-- feature box -->
              <div class="text-center transition hover-top box-shadow white-bg border-radius-5 p-40px border-bottom-5 border-color-theme m-30px-tb">
                <div class="f-icon-100px font-30px white-color border-radius-effect" style="background-color: #ED217C;">
                  <i class="icon-tools"><img src="../../assets/startup_color.svg"></i>
                </div>
                <div class="p-25px-t">
                  <h5 class="font-w-500 font-18px dark-color m-0px">SmartGovs</h5>
                </div>
              </div>
              <!-- end feature box -->
              <!-- feature box -->
              <div class="text-center transition hover-top box-shadow white-bg border-radius-5 p-40px border-bottom-5 border-color-theme m-30px-tb">
                <div class="f-icon-100px font-30px theme-bg white-color border-radius-effect" style="background-color: #8A4FFF;">
                  <i class="icon-tools"><img src="../../assets/prefeitura.png"></i>
                </div>
                <div class="p-25px-t">
                  <h5 class="font-w-500 font-18px dark-color m-0px">Poder Público</h5>
                </div>
              </div>
              <!-- end feature box -->
            </div>
          </div>
        </div>
      </div>

    </div>
  </section>

</template>

<script>
export default {
name: "HomeSobre"
}
</script>

<style scoped>

</style>